const React = require('react');
const { string } = require('prop-types');
const classnames = require('classnames');
const Image = require('nordic/image');

const ICON_ID = 'rep_seller_delivery_time_bad_v2';
const namespace = 'ui-pdp-icon ui-pdp-icon--time-negative';

const IconTimeNegativeV2 = ({ className }) => (
  <div className={classnames(namespace, className)}>
    <Image src="time-negative-v2.svg" alt="" />
  </div>
);

IconTimeNegativeV2.propTypes = {
  className: string,
};

IconTimeNegativeV2.defaultProps = {
  className: null,
};
module.exports = React.memo(IconTimeNegativeV2);
exports = module.exports;
exports.ICON_ID = ICON_ID;
